<template>
  <div class="header_wrap">
    <!-- pc端头部 -->
    <div class="pcheader">
      <div class="pcheader_top f-wrap">
        <div class="logo"></div>
        <div class="download">
          <div class="left">
            <div class="btn">AppStore下载</div>
            <div class="btn">Android下载</div>
          </div>
          <div class="right"></div>
        </div>
      </div>
      <div class="pcheader_nav">
        <ul class="f-wrap f-f0">
          <li v-for="(item, index) in computedNvaList" :key="index">
            <router-link :to="item.path">{{ item.title }}</router-link>
          </li>
        </ul>
      </div>
    </div>
    <!-- 移动端头部 -->
    <div class="mbheader">
      <div class="static_header">
        <div class="mblogo"></div>
        <div
          :class="{ mbmenuactive: menuflag, mbmenu: true }"
          @click="menubtn()"
        ></div>
      </div>
      <!-- 下拉菜单 -->
      <div :class="{ menublock: menuflag, menu: true }">
        <ul>
          <li v-for="(item, index) in computedmbNvaList" :key="index">
            <router-link :to="item.path">{{ item.title }}</router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { indexApi } from "@/api/module.js";
export default {
  name: "MyHeader",
  data() {
    return {
      configswitch: false,
      menuflag: false,
      timer: null,
      nvaList: [
        {
          title: "首页",
          path: "/home",
        },
        {
          title: "热门商家",
          path: "/popularBusiness",
        },
        {
          title: "潮流圈",
          path: "/trendCircle",
        },
        {
          title: "关于我们",
          path: "/aboutus",
        },
      ],
      mbnvaList: [
        {
          title: "首页",
          path: "/home",
        },
        {
          title: "热门商家",
          path: "/popularBusiness",
        },
        {
          title: "潮流圈",
          path: "/trendCircle",
        },
        {
          title: "关于我们",
          path: "/aboutus",
        },
        {
          title: "APP下载",
          path: "/appdownload",
        },
      ],
    };
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）
  mounted() {
    this.menuswitchFn();
  },
  methods: {
    //移动端菜单按钮
    menubtn() {
      // 防抖
      if (this.timer !== null) {
        clearTimeout(this.timer);
      }
      this.timer = setTimeout(() => (this.menuflag = !this.menuflag), 200);
    },
    //菜单获取数据
    menuswitchFn() {
      indexApi.getSystemConfigFn({}).then((res) => {
        if (res.status == 200) {
          this.configswitch = res.data.data.pc_h5_menu == 1 ? true : false;
        }
      });
    },
  },
  computed: {
    computedNvaList: function () {
      let temparr = [...this.nvaList]
      if (!this.configswitch) {
        let that = this;
        temparr.forEach(function (item, index) {
          if (item.path == "/trendCircle" || item.path == "/popularBusiness") {
            delete temparr[index];
          }
        });
        temparr = temparr.filter(function (val) {
          return val;
        });
        return temparr
      }else{
          return this.nvaList
      }
    },
    computedmbNvaList: function () {
      let temparr = [...this.mbnvaList]
      if (!this.configswitch) {
        let that = this;
        temparr.forEach(function (item, index) {
          if (item.path == "/trendCircle" || item.path == "/popularBusiness") {
            delete temparr[index];
          }
        });
        temparr = temparr.filter(function (val) {
          return val;
        });
        return temparr
      }else{
          return this.mbnvaList
      }
    },
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        //移动端点击跳转后收起菜单
        if (val.fullPath != oldVal.oldVal) this.menuflag = false;
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/style/index.less";

.less_mb_header(@mb_number) {
  .pcheader {
    //750以下不显示
    display: none;
  }
  .menuactive {
    display: block;
  }
  .header_wrap {
    padding: (44px / @mb_number) (30px / @mb_number);
    .mbheader {
      position: relative;
      .static_header {
        .felx_space();
        .mblogo {
          width: (146px / @mb_number);
          height: (62px / @mb_number);
          background: url(../../assets/mobile/header/logo@2x.png) no-repeat;
          background-size: 100% 100%;
        }
        .mbmenu {
          width: (46px / @mb_number);
          height: (40px / @mb_number);
          background: url(../../assets/mobile/header/menu@2x.png) no-repeat;
          background-size: 100% 100%;
          transition: all 0.1s;
        }
        .mbmenuactive {
          // width: 46px;
          // height: 40px;
          // background: url(../../assets/mobile/header/menu@2x.png) no-repeat;
          // background-size: 100% 100%;
          transform: rotateZ(-90deg);
          transition: all 0.1s;
        }
      }

      .menu {
        width: (360px / @mb_number);
        height: 0;
        //   transform: scale(0);
        background: url(../../assets/mobile/header/menu_bg.png) no-repeat;
        background-size: 100% 100%;
        position: absolute;
        right: 0;
        margin-top: (44px / @mb_number);
        padding-top: 0;
        overflow: hidden;
        transition: all 0.1s;
        z-index: 99999;
        ul {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          li {
            height: (20% / @mb_number);
            width: 100%;
            text-align: center;
            border-top: 1px solid #787878;
            flex: 1 1 auto;
            a {
              width: 100%;
              height: 100%;
              .felx_center();
              font-size: (40px / @mb_number);
              font-family: PingFang SC;
              font-weight: 500;
              color: #ffffff;
            }
            .active {
              color: #fff;
              background: #f02420;
            }
          }
          li:first-of-type {
            border: none;
          }
        }
      }
      .menublock {
        height: (614px / @mb_number);
        padding-top: (29px / @mb_number);
        //    transform: scale(1);
        transition: all 0.1s;
      }
    }
  }
}
@media screen and (max-width: 375px) {
  .less_mb_header(2);
}
@media screen and (min-width: 376px) and (max-width: 750px) {
  .less_mb_header(2);
}
@media screen and (min-width: 751px) and (max-width: 1200px) {
  .pcheader_top{
    width: 100% !important;
  }
  .pcheader_nav{
    width: 100% !important;
    ul{
          width: 100% !important;

    }
  }
}
@media screen and (min-width: 751px) {
  .mbheader {
    //关闭移动端
    display: none;
  }
  .header_wrap {
    height: auto;
    padding: 15px 0 0;
    margin: 0 auto;
    background: #fff;
    .pcheader {
      display: block;
      .pcheader_top {
        width: 1200px;
        margin: 10px auto;
        .felx_space();
        .logo {
          width: 146px;
          height: 62px;
          background: url(../../assets/pc/header/logo@2x.png) no-repeat;
          background-size: 100% 100%;
          cursor: pointer;
        }
        .download {
          .felx_space();
          .left {
            width: 120px;
            .btn {
              border: 1px solid #1d1d1d;
              border-radius: 6px;
              font-size: 12px;
              color: #222222;
              padding: 7px;
              background: url(../../assets/pc/header/Android@2x.png) 10px center
                no-repeat;
              background-size: 16px 18px;
              padding-left: 30px;
              cursor: pointer;
            }
            .btn:first-of-type {
              margin-bottom: 8px;
              background: url(../../assets/pc/header/apple@2x.png) 10px center
                no-repeat;
              background-size: 16px 18px;
            }
          }
          .right {
            width: 70px;
            height: 70px;
            background: url(../../assets/mobile/appcode.png) no-repeat center;
            background-size: 100%;
            margin-left: 10px;
            cursor: pointer;
            // img{
            //     width: 100%;
            //     height: 100%;
            // }
          }
        }
      }
      .pcheader_nav {
        height: 48px;
        line-height: 48px;
        margin-top: 25px;
        background: #f02420;

        ul {
          width: 1200px;
          margin: 0 auto;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          li {
            width: 120px;
            height: 48px;
            text-align: center;
            cursor: pointer;
            display: inline-block;
            a {
              display: block;
              color: #ffffff;
            }
            &:hover {
              color: #fff;
              background: #000000;
            }
          }
          .active {
            color: #fff;
            background: #000000;
          }
        }
      }
    }
  }
}
</style>
