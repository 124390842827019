import Vue from 'vue'
import 'element-ui/lib/theme-chalk/index.css'

// 引入组件
import {
    Tabs,
    TabPane,
    Pagination,
    Message,
    Carousel,
    CarouselItem
}from 'element-ui'
const element = {
  install: function (Vue) {
    Vue.use(Tabs)
    Vue.use(TabPane)
    Vue.use(Pagination)
    Vue.use(Carousel);
    Vue.use(CarouselItem);
 }
}
Vue.use(element)
Vue.prototype.$message = Message;