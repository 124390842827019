import { URL } from '@/constants/index';
import axios from '@/config/axios.config';

export { URL, axios };

// 商品分类
export async function getGoodsClass(params, options = {}) {
    return axios({
        url: URL.getGoodsClass,
        method: 'get',
        headers: {
            'http-platform': 'h5',
            'http-total': 1,
            'http-random': 1,
            'http-version': 1,
        },
        params,
        ...options,
    });
}

// 商家接口
export async function getBrandBusinessFn(params, options = {}) {
    return axios({
        url: URL.getBrandBusiness,
        method: 'get',
        headers: {
            'http-platform': 'h5',
            'http-total': 1,
            'http-random': 1,
            'http-version': 1,
        },
        params,
        ...options,
    });
}
// 商家详情
export async function getUserDetails(params, options = {}) {
    return axios({
        url: URL.getUserDetails,
        method: 'get',
        headers: {
            'http-platform': 'h5',
            'http-total': 1,
            'http-random': 1,
            'http-version': 1,
        },
        params,
        ...options,
    });
}
// 测试
export async function githubFn(params, options = {}) {
    return axios({
        url: URL.github,
        method: 'get',
        params,
        ...options,
    });
}
// 商家详情
export async function getdynamDetails(params, options = {}) {
    return axios({
        url: URL.dynamDetails,
        method: 'get',
        headers: {
            'http-platform': 'h5',
            'http-total': 1,
            'http-random': 1,
            'http-version': 1,
        },
        params,
        ...options,
    });
}

// pc&h5的热门商家和潮流圈菜单开关接口
export async function getSystemConfigFn(params, options = {}) {
    return axios({
        url: URL.getSystemConfig,
        method: 'get',
        headers: {
            'http-platform': 'h5',
            'http-total': 1,
            'http-random': 1,
            'http-version': 1,
        },
        params,
        ...options,
    });
}

// 帮助
export async function getChaosListFn(params, options = {}) {
    return axios({
        url: URL.getChaosList,
        method: 'get',
        headers: {
            'http-platform': 'h5',
            'http-total': 1,
            'http-random': 1,
            'http-version': 1,
        },
        params,
        ...options,
    });
}

//帮助详情（文章详情）
export async function getContentDetails(params, options = {}) {
    return axios({
        url: URL.getContentDetails,
        method: 'get',
        headers: {
            'http-platform': 'h5',
            'http-total': 1,
            'http-random': 1,
            'http-version': 1,
        },
        params,
        ...options,
    });
}

//banner
export async function getBanner(params,options={}){
    return axios({
        url: URL.getBannerdata,
        method: 'get',
        headers: {
            'http-platform': 'h5',
            'http-total': 1,
            'http-random': 1,
            'http-version': 1,
        },
        params,
        ...options,
    });
}