//测试
export const github = '/search/users';

// 商品分类接口：
export const getGoodsClass = '/api/method/getGoodsClass';

// 商家接口：
export const getBrandBusiness = '/api/method/getBrandBusiness';

// 商家详情接口:
export const getUserDetails = '/api/method/getUserDetails';

// 帮助中心接口:
export const getChaosList = '/api/method/getChaosList';

// 帮助详情接口:
export const getContentDetails = '/api/method/getContentDetails';
// 动态详情接口:
export const dynamDetails = '/api/method/getDynamicDetails';

// pc&h5的热门商家和潮流圈菜单开关接口:
export const getSystemConfig = '/api/method/getSystemConfig';

//banner
export const getBannerdata = '/api/method/wwwad';
