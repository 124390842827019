import Vue from "vue";
import Vuex from "vuex";
import { STORAGE_KEY,SESSION_KEY } from "@/constants/config";
// vuex本地持久化
import createPersistedState from "vuex-persistedstate";
import createLogger from "vuex/dist/logger";
import { indexApi } from "@/api/module.js";
Vue.use(Vuex);
const plugins = [
  createPersistedState({ // localStorage持久化处理
    key: STORAGE_KEY,
    paths: [
      // 'test',
    ],
  }),createPersistedState({ // sessionStorage持久化处理
    storage: window.sessionStorage,
    key: SESSION_KEY,
    paths: [
      // 'test',
    ]
  })
];
process.env.NODE_ENV === "development" ? plugins.push(createLogger()) : null;

export default new Vuex.Store({
  plugins,
  state: {
    // test: false,
    footerHeight:0  //底部高度
  },
  mutations: {
    setFooterHeight(state, data) {
       state.footerHeight = data;
    },
  },
  actions: {
    /**
     * 获取底部高度
     */
     getFooterHeight({
      commit
    },data) {
      commit('setFooterHeight', data);
    },
  },
  modules: {}
});
