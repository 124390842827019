import { TIME_OUT } from "@/constants/config";
import Axios from "axios";

const baseURL =
  process.env.NODE_ENV === "development"
    ? "/"
    : process.env.VUE_APP_API_URL;
    console.log(baseURL)
const axiosInstance = Axios.create({
  timeout: TIME_OUT * 1000,
  // timeout:10000,
  baseURL: baseURL
});

// axiosInstance.interceptors.request.use(
//   config => {
//     return config;
//   },
//   error => { }
// );

// axiosInstance.interceptors.response.use(
//   response => {
//     let data = response.data;
//     return data;
//   },
//   error => {
//   }
// );
export default axiosInstance;
