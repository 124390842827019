<template>
    <div id="app">
        <MyHeader v-if="this.$route.path!='/appdownload'"/>
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive" />
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive" />
        <MyFooter v-if="this.$route.path!='/appdownload'" />
    </div>
</template>

<script>
import MyHeader from "@/components/header/header.vue";
import MyFooter from "@/components/footer/footer.vue";

export default {
    name: "App",
    components: {
        MyHeader,
        MyFooter,
    },
};
</script>

<style>
body {
    font-family: Microsoft YaHei;
    font-weight: 400;
}
</style>
