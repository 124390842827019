import Vue from 'vue';
import VueRouter from 'vue-router';

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch((error) => error);
};
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: {
            name: 'home',
        },
    },
    {
        path: '/home',
        name: 'home',
        meta: {
            title: '安贸通首页',
            // keepAlive:true
        },
        component: (resolve) => require(['@/pages/home'], resolve),
    },
    {
        path: '/popularBusiness',
        name: 'popularBusiness',
        meta: {
            title: '热门商家',
            // keepAlive:true
        },
        component: (resolve) => require(['@/pages/popularBusiness'], resolve),
    },
    {
        path: '/popularBusiness/commodity',
        name: 'commodity',
        meta: {
            title: '热门模块',
            // keepAlive:true
        },
        component: (resolve) => require(['@/pages/commodity'], resolve),
    },
    {
        path: '/popularBusiness/business',
        name: 'business',
        meta: {
            title: '商家',
            // keepAlive:true
        },
        component: (resolve) => require(['@/pages/business'], resolve),
    },
    {
        path: "/trendCircle",
        name: "trendCircle",
        meta: {
        title: '热门商家',
        // keepAlive:true
        },
        component: resolve => require(['@/pages/trendCircle'], resolve)
    },
    {
        path: "/aboutus",
        name: "aboutus",
        meta: {
        title: '关于我们',
        // keepAlive:true
        },
        component: resolve => require(['@/pages/aboutus'], resolve)
    },
    {
        path: "/helpCenter",
        name: "helpCenter",
        meta: {
        title: '帮助中心',
        // keepAlive:true
        },
        component: resolve => require(['@/pages/helpCenter'], resolve)
    },
    {
        path: "/articleDetails",
        name: "articleDetails",
        meta: {
        title: '文章详情',
        // keepAlive:true
        },
        component: resolve => require(['@/pages/articleDetails'], resolve)
    },
    {
        path: "/dynamDetails",
        name: "dynamDetails",
        meta: {
        title: '动态详情',
        // keepAlive:true
        },
        component: resolve => require(['@/pages/dynamDetails'], resolve)
    },
    {
        path: "/appdownload",
        name: "appdownload",
        meta: {
        title: '动态详情',
        // keepAlive:true
        },
        component: resolve => require(['@/pages/appdownload'], resolve)
    }
    
];

const router = new VueRouter({
    //mode: 'history',
    mode: "hash",
    base: process.env.BASE_URL,
    linkActiveClass: 'active',
    linkExactActiveClass: 'active',
    scrollBehavior(to, from, savedPosition) {
        if (to.meta.title) {
            document.title = to.meta.title;
        } else {
            document.title = '安贸通';
        }
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes,
});

export default router;
