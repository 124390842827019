import copyComponent from './copy';
let $vm;
export default {
    // eslint-disable-next-line no-unused-vars
    install(Vue, options) {
        if (!$vm) {
            const LoadingPlugin = Vue.extend(copyComponent);
            $vm = new LoadingPlugin({
                el: document.createElement('div'),
            });

            document.body.appendChild($vm.$el);
        }

        $vm.show = false;

        let copy = {
            show(text) {
                $vm.show = true;
                $vm.text = text;
            },
            hide() {
                $vm.show = false;
            },
        };

        if (!Vue.$copy) {
            Vue.$copy = copy;
        }

        // Vue.prototype.$loading = Vue.$loading;

        Vue.mixin({
            created() {
                this.$copy = Vue.$copy;
            },
        });
    },
};
