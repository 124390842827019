<template>
    <div class="box" v-show="show" @touchmove.prevent>
        <div class="mask"></div>
        <div class="content">
            <div class="animate">
                <img src="@/assets/success.png" />
            </div>
            <div class="text">{{ text }}</div>
            <div class="btn" @click="show = false">我知道了</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'copy',
    props: {
        show: Boolean,
        text: {
            type: String,
            default: '微信号已复制，快打开微信添加吧！',
        },
    },
    components: {},
    data() {
        return {};
    },
    computed: {},
    methods: {},
    created() {},
    mounted() {},
};
</script>
<style scoped>
.box {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 5000;
    background-color: rgba(10, 10, 10, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.box .content {
    width: 280px;
    height: 194px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.box .content .animate img{
    width: 50px;
    height: 50px;
}
.box .text {
    font-size: 13px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #2a2a2a;
    line-height: 20px;
    margin: 18px auto 23.5px;
}
.box .btn {
    width: 180px;
    height: 40px;
    background: #f02420;
    border-radius: 20px;
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
}
.box .animate {
    width: 100%;
    text-align: center;
}
</style>
