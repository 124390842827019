import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/index';
import '@/style/index.less';
import Copy from './components/copy/index';
import VueLazyload from 'vue-lazyload';

// 判断移动端还是pc端 <!--_isMobile方法-->
Vue.prototype.$isMobile = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
Vue.config.productionTip = false;
Vue.use(Copy);
Vue.use(VueLazyload, {
    preLoad: 1.3, //距离当前dom距离页面底部的高度
    error: '', //加载失败显示的图片
    loading: '', //加载中显示的图片
    attempt: 1, // 图片加载失败，最多重试的次数
});

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
